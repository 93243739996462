
import Filterable from "../../../../components/Header/Tables/Filterable.vue";
import { SortingOption } from "../../../../interfaces/paginated";
import { isNull } from "lodash";
import Vue from "vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

export default Vue.extend({
	name: "SegmentsTable",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default: [],
		},
		limit: {
			type: Number,
			default: 25,
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		Filterable,
		IconBase,
	},
	data: () => ({
		radios: false,
		filter: {
			id: {
				value: "",
				order: "asc",
			},
			advertiser_name: {
				value: "",
				order: "asc",
			},
			key: {
				value: "",
				order: "asc",
			},
			name: {
				value: "",
				order: "asc",
			},
			description: {
				value: "",
				order: "asc",
			},
			user_count_nam: {
				value: "",
				order: "asc",
			},
			user_count_emea: {
				value: "",
				order: "asc",
			},
			update_date: {
				value: "",
				order: "asc",
			},
			create_date: {
				value: "",
				order: "asc",
			},
			count_update_date: {
				value: "",
				order: "asc",
			},
			active: {
				value: "",
				order: "asc",
			},
		},
		filtered: [],
	}),

	created() {},

	mounted() {},

	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
	},

	methods: {
		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},

		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},

		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},

		removeOption() {
			this.$emit("remove-option");
		},

		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},
		
		rowClick(item: { id: number }, slot: any, type: any) {
			switch (type) {
				case "line":
					this.$router.push({
						name: "SegmentsIndex"
					});
					event?.stopPropagation();
					break;
				case "row":
					this.$router.push({
						name: "SegmentsEdit",
						params: { id: item.id },
					});
					break;
			}
		},
	},
});
